import React, { FC, useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import 'react-dropzone-uploader/dist/styles.css'
import './styles.css'
import Dropzone, { IStyleCustomization } from 'react-dropzone-uploader'
import {
  Edit,
  SimpleForm,
  SimpleList,
  Datagrid,
  TextInput,
  TextField,
  ReferenceField,
  ReferenceManyField,
  ChipField,
  DateTimeInput,
  Labeled,
  Toolbar,
  Resource,
  SaveButton,
  FormDataConsumer,
  useTheme,
  useCreatePath,
  useRecordContext,
  useDataProvider,
  useRedirect,
  useNotify,
  TabbedShowLayout,
} from 'react-admin';
import { AltTextField } from '../Components';

const IdentifierField = ({ label }:any) => {
    const record = useRecordContext();
    return (
        <Labeled label={label}>
            <Typography>{record.id}</Typography>
        </Labeled>
    );
};

const DropzoneUploader: FC = (props) => {
  const [theme, setTheme] = useTheme();
  const {getValues, setValue} = useFormContext();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const file_list: string[] = useRef([]).current;
  const files: Object[] = useRef([]).current;

 // TODO pull all (most) of this in from MUI styles:
 
  const baseStyle:any = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '180px',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    overflow: 'hidden',
  };

  const textStyle:any = {
    padding: '20px',
    color: 'inherit',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: '500',
  };

  const inputLabelWithFilesStyle:any = {
    color: (theme == 'dark') ? '#fff' : '#000',
    backgroundColor: (theme == 'dark') ? '#4b4b4b' : '#e0e0e0',
    borderRadius: '18px',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    textTransform: 'uppercase',
    minWidth: '64px',
    padding: '6px 16px',
  };

  const rejectTextStyle:any = {
    padding: '20px',
    color:  '#F00',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: '500',
  };

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    backgroundColor: '#DAA',
  };

  const getUploadParams = async ({ meta: { name } }:any) => {
    const { data, isLoading, error } = await dataProvider.getPresignedUrl('upload', name, record.id)
    const { fields, url } = data
    return { fields, meta: { name, meetingId: record.id }, url }
  }
  
  const createMeetingFile = async (name:any) => {
    const { data, isLoading, error } = await dataProvider.createMeetingFile(name, record.id)
    return {}
  }

// called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }:any, status:any) => {
    console.log(status, meta, file) 
    if (status === 'preparing') {
      file_list.push(meta.id);
      files.push(file);
      console.log(file_list) 
      console.log(files) 
    }
    if (status === 'headers_received') {
      createMeetingFile(meta.name)
      const index = file_list.indexOf(meta.id, 0);
      if (index > -1) {
	 file_list.splice(index, 1);
      }
      console.log(file_list) 
      if (file_list.length == 0) {
	setValue('status', 'Uploaded', { shouldTouch: true, shouldDirty: true } )
	setValue('hiddenStatus', 'Uploaded', { shouldTouch: true, shouldDirty: true } )
	console.log('All files uploaded') 
	notify("All files uploaded, choose 'Analyse Meeting' to continue", { autoHideDuration: 60000 });
      }
    }
  }
  
  const rdzuStyles:IStyleCustomization<React.CSSProperties> = {
	  dropzone: baseStyle,
	  dropzoneActive: focusedStyle,
	  dropzoneReject: rejectStyle,
	  inputLabel: (files, extra) => (extra.reject ? rejectTextStyle : textStyle),
	  inputLabelWithFiles: inputLabelWithFilesStyle,
  };

  return (
  <>
    {getValues('hiddenStatus') !== 'Processing...' &&
      <Dropzone
	styles={rdzuStyles}
	getUploadParams={getUploadParams}
	onChangeStatus={handleChangeStatus}
	submitButtonContent=''
	inputWithFilesContent='Add More Files'
	canCancel={false}
	canRemove={false}
	inputContent={(files, extra) => (extra.reject ? 'Not a valid file type' : "Drag 'n' Drop your audio recordings here, or click to select files")}
	accept="audio/*,.mp4,.tsv,.csv"
      />
    }
  </>
  )
}


const Aside = () => {
  const createPath = useCreatePath();
  const clauses = [
    <>See <Link to={createPath({ resource: 'help', type: 'list' }) }>Help</Link> section for how to download files from Zoom</>,
    'Upload individual audio .mp4/.m4a files',
  ];
  const listItems = clauses.map((clause,index) => <li key={"clause"+index}><Typography variant="body2">{clause}</Typography></li>);
  return (
    <Box sx={{ width: {xs: 'auto', lg:'40%'}, margin: '1em 1em 100px 2em' }}>
      <Typography variant="h6">Instructions</Typography>
      <ol>{listItems}</ol>
    </Box>
  )
};

const MeetingEditToolbar: FC = (props) => {
  const { dropzoneKey, resetDropzone, ...rest } = props
  const notify = useNotify();
  const record = useRecordContext();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const {getValues, setValue, reset, watch } = useFormContext();
  const [lastStatus, setLastStatus] = useState('default');
  const [status, hiddenStatus] = watch(["status", "hiddenStatus"]);

  useEffect(() => {
    if (lastStatus == "Uploaded" && status == "Processing..."){
      setValue('hiddenStatus', 'Processing...', { shouldTouch: true, shouldDirty: true } )
    }
    if (lastStatus == "Processing..." && status == "Report Generated"){
      console.log('Meeting processed');
      notify("Meeting processed, you may now view the report", { autoHideDuration: 60000 });
      redirect('show', 'reports', record.report_id);
    }
    if (status !== lastStatus){
      setLastStatus(status);
    }
  });

  const analyseMeeting = async () => {
    const { data, isLoading, error } = await dataProvider.analyseMeeting(record.id)
    console.log('Processing meeting');
    notify('Your meeting is being processed, this may take a few minutes...', { autoHideDuration: 60000 });
    setValue('hiddenStatus', 'Processing...', { shouldTouch: true, shouldDirty: true } )
    resetDropzone(!dropzoneKey)
    return {}
  }
  return (
    <Toolbar {...rest}>
      {getValues('hiddenStatus') !== 'Uploaded' &&
	<SaveButton
	  disabled={getValues('hiddenStatus') === 'Processing...'}
	/>
      }
      {getValues('hiddenStatus') === 'Uploaded' &&
	<SaveButton
	  label="Analyse Meeting"
	  mutationOptions={{
	      onSuccess: () => {
		  analyseMeeting();
	      }}
	  }
	  type="button"
	  icon={<TrendingUpIcon />}
	  alwaysEnable={true}
	/>
      }
    </Toolbar>
  )
}

const MeetingEdit: FC = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [dropzoneKey, resetDropzone] = useState(true);

  return (
    <Edit 
      aside={<Aside />} 
      sx={{
	'& .RaEdit-main': {
	  flexDirection: {xs: 'column', md: 'row'},
	},
	'& .RaEdit-card': {
	  minWidth: {xs: '98%', md: 'auto'},
	},
      }} 
      mutationMode="pessimistic"
      queryOptions={{
        refetchInterval: (record) => [ 'Uploaded', 'Processing...' ].includes(record?.status) ? 1000 : false,
      }}
      {...props}
    >
  {/*	  <Resource
		  name="users"
		  recordRepresentation={(record) => `${record.full_name}`}
		  list={UserList}
	    />
  */}
      <TabbedShowLayout>
	<TabbedShowLayout.Tab label="Details">

      <SimpleForm toolbar={<MeetingEditToolbar dropzoneKey={dropzoneKey} resetDropzone={resetDropzone}/>}>
	<TextInput disabled source="id" />
	<TextInput source="unique_id" label="Meeting Title"/>
	<DateTimeInput source="date_time" defaultValue={new Date()} />
	<TextInput source="status" disabled />
	<FormDataConsumer>
	  {({ formData, ...rest }:any) => formData.status == 'Error' &&
	    <TextInput source="error_message" disabled multiline fullWidth />
	  }
	</FormDataConsumer>
	<ReferenceField
	  resource="meetings"
	  source="report_id"
	  reference="reports"
	  link="show"
	>
	  <Labeled>
	    <ChipField source="filename" label="Report(s)" icon={<TrendingUpIcon/>} />
	  </Labeled>
	</ReferenceField>
	{/*<TextInput source="name" />
	<TextInput source="description" />*/}
	    {/*<ReferenceField label="Owner" source="owner_id" reference="users">
		  <TextField source="full_name" />
	</ReferenceField>
  */}
	<DropzoneUploader key={dropzoneKey} />
      </SimpleForm>
	</TabbedShowLayout.Tab>
	<TabbedShowLayout.Tab label="Files" path="files">
	  <ReferenceManyField
	    label={false}
	    reference="meeting_files"
	    target="meeting_id"
	    sort={{ field: 'file_name', order: 'DESC' }}
	  >
	    <Datagrid>
	      {isSmall || ( <TextField source="id" /> )}
	      <TextField source="file_name" />
	      {isSmall || ( <TextField source="file_type" /> )}
	      
  {/*            <ReferenceField
		resource="meeting_files"
		source="participant_id"
		reference="participants"
		link="edit"
	      >
		<AltTextField sources={["name", "email"]} />
	      </ReferenceField>*/}
	    </Datagrid>
	  </ReferenceManyField>
	</TabbedShowLayout.Tab>
      </TabbedShowLayout>

    </Edit>
  );
};

export default MeetingEdit;
