import EventIcon from '@mui/icons-material/Event';

import MeetingEdit from './MeetingEdit';
import MeetingList from './MeetingList';
import MeetingCreate from './MeetingCreate';

export default {
  create: MeetingCreate,
  edit: MeetingEdit,
  list: MeetingList,
  icon: EventIcon
};

