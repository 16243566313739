import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import 'react-dropzone-uploader/dist/styles.css'
import './styles.css'
import Dropzone, { IStyleCustomization } from 'react-dropzone-uploader'
import {
  Create,
  useTheme,
  useCreatePath,
  useDataProvider,
  useRedirect,
  useNotify,
} from 'react-admin';

const DropzoneUploader = () => {
  const [theme, setTheme] = useTheme();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();

 // TODO pull all (most) of this in from MUI styles:
 
  const baseStyle:any = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    minHeight: '180px',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    overflow: 'hidden',
  };

  const textStyle:any = {
    padding: '20px',
    color: 'inherit',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: '500',
  };

  const inputLabelWithFilesStyle:any = {
    color: (theme == 'dark') ? '#fff' : '#000',
    backgroundColor: (theme == 'dark') ? '#4b4b4b' : '#e0e0e0',
    borderRadius: '18px',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    textTransform: 'uppercase',
    minWidth: '64px',
    padding: '6px 16px',
  };

  const rejectTextStyle:any = {
    padding: '20px',
    color:  '#F00',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: '500',
  };

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    backgroundColor: '#DAA',
  };

  const getUploadParams = async ({ meta: { name } }:any) => {
    const { id, unique_id } = await dataProvider.create('meetings', { data: {} })
      .then(({ data }) => {
        console.log(`meeting created ${data}`);
        return data
      })
      .catch(error => {
        console.log(`meeting error ${error}`);
      })
    console.log(`meeting created #${id}: ${unique_id}`);
    const { data, isLoading, error } = await dataProvider.getPresignedUrl('upload', name, id)
    const { fields, url } = data
    return { fields, meta: { name, meetingId: id }, url }
  }
  
  const createMeetingFile = async (name:any, id:any) => {
    const data = await dataProvider.createMeetingFile(name, id)
      .then(({ data }) => {
        console.log(`meeting file birthed ${data}`);
        return data
      })
      .catch(error => {
        console.log(`meeting file error ${error}`);
      })
    console.log(`meeting_file created #${data.id}: ${data.file_name}`);
    return data;
  }

  const getUpdatedMeeting = async (meeting_id:any) => {
    const meeting = await dataProvider.getOne('meetings', { id: meeting_id })
      .then(({ data }) => {
        console.log(`meeting gotted ${data}`);
        return data
      })
      .catch(error => {
        console.log(`meeting error ${error}`);
      })
    console.log(`meeting gotted #${meeting.id}: ${meeting.unique_id}`);
    return meeting;
  }
  
  const deleteFailedMeeting = async (meeting_id:any) => {
    const meeting = await dataProvider.delete('meetings', { id: meeting_id })
      .then(({ data }) => {
        console.log(`meeting byebyed ${data}`);
        return data
      })
      .catch(error => {
        console.log(`meeting error ${error}`);
      })
    console.log(`meeting byebyed #${meeting.id}: ${meeting.unique_id}`);
    return meeting;
  }
  
// called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file, remove }:any, status:any) => {
    console.log(status, meta, file) 
    if (status === 'headers_received') {
      const meeting_file = await createMeetingFile(meta.name, meta.meetingId);
      const meeting = await getUpdatedMeeting(meta.meetingId);
      if (meeting.status == 'Created') {
        redirect('edit', 'meetings', meta.meetingId);
        notify('Particpants.csv file processed.');
      } else {
        await deleteFailedMeeting(meta.meetingId);
        notify(meeting.error_message, { type: 'error', autoHideDuration: 30000 });
        remove();
      }
    }
  }
  
  const rdzuStyles:IStyleCustomization<React.CSSProperties> = {
	  dropzone: baseStyle,
	  dropzoneActive: focusedStyle,
	  dropzoneReject: rejectStyle,
	  inputLabel: (files, extra) => (extra.reject ? rejectTextStyle : textStyle),
	  inputLabelWithFiles: inputLabelWithFilesStyle,
  };

  return (
    <Dropzone
      styles={rdzuStyles}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      submitButtonContent=''
      inputWithFilesContent='Add More Files'
      maxFiles={1}
      multiple={false}
      canCancel={false}
      canRemove={false}
      inputContent={(files, extra) => (extra.reject ? 'Not a valid file type' : "Drag 'n' Drop your participants.csv here, or click to select a file")}
      accept=".csv"
    />
  )
}

const Aside = () => {
  const createPath = useCreatePath();
  const clauses = [
    <>See <Link to={createPath({ resource: 'help', type: 'list' }) }>Help</Link> section for how to download files from Zoom</>,
    'Upload Participants.csv file',
  ];
  const listItems = clauses.map((clause,index) => <li key={"clause"+index}><Typography variant="body2">{clause}</Typography></li>);
  return (
    <Box sx={{ width: {xs: 'auto', lg:'40%'}, margin: '1em 1em 100px 2em' }}>
      <Typography variant="h6">Instructions</Typography>
      <ol>{listItems}</ol>
    </Box>
  )
};


const MeetingCreate: FC = (props) => (
  <Create title="Upload Meeting" aside={<Aside />} sx={{
    '& .RaCreate-main': {
      flexDirection: {xs: 'column', md: 'row'},
    },
  }} {...props}>
    <DropzoneUploader />
  </Create>
);

export default MeetingCreate;
