import React, { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Title, } from 'react-admin';

export const Privacy: FC = () => {
  const clauses = [
    'All information is stored securely',
    'Meeting content is not analysed – we only analyse who is speaking.',
    'All recordings are deleted after processing.',
    'We do not share your data with any 3rd parties',
    'You can delete your personal data, (including meetings and reports) at any time.'
  ];
  const listItems = clauses.map((clause,index) => <li key={"clause"+index}><Typography variant="body2">{clause}</Typography></li>);
  return (
    <Card>
      <Title title="Privacy Statement" />
      <CardContent>
        <Typography variant="h4">
        Privacy Statement
        </Typography>
        <ul>{listItems}</ul>
      </CardContent>
    </Card>
  )
};
