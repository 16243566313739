import * as React from 'react';
import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import decodeJwt from 'jwt-decode';
import {
    Form,
    required,
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
    useAuthProvider,
    useNotificationContext,
} from 'ra-core';
import { TextInput } from 'react-admin';

export const ResetPassword = (props: LoginFormProps) => {
    const { redirectTo, className } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const authProvider = useAuthProvider();
    const navigate = useNavigate();
    const { resetNotifications } = useNotificationContext();
    const { token } = Object.fromEntries(new URLSearchParams(useLocation().search))

    const passwordReset = useCallback(
        (params: any = {}, pathName) =>
            authProvider.passwordReset(params).then(ret => {
                resetNotifications();
                // navigate('/login');
                return ret;
            }),
        [
            authProvider,
            navigate,
            resetNotifications,
        ]
    );

    const submit = (values: FormData) => {
        setLoading(true);
        values['token'] = token;
        passwordReset(values, redirectTo)
            .then(({ msg }) => {
                setLoading(false);
                notify(msg);
            }).then(() => {
                setLoading(true);
                login(values, redirectTo)
                    .then(() => {
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        notify(
                            typeof error === 'string'
                                ? error
                                : typeof error === 'undefined' || !error.message
                                ? 'ra.auth.sign_in_error'
                                : error.message,
                            {
                                type: 'error',
                                messageArgs: {
                                    _:
                                        typeof error === 'string'
                                            ? error
                                            : error && error.message
                                            ? error.message
                                            : undefined,
                                },
                            }
                        );
                    });
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    }
                );
            });
    };

    const PasswordValidate = values => {
        const errors = {};
        if (!values.password) {
            errors.password = 'Required';
        }
        if (!values.confirmPassword ) {
            errors.confirmPassword = 'Required' ;
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Password mismatched' ;
        }

        return errors;
    };

    const decodedToken: any = decodeJwt(token);

    return (
        <StyledForm
            onSubmit={submit}
            mode="onChange"
            validate={PasswordValidate}
            className={className}
        >
            <CardContent className={LoginFormClasses.content}>
                <Typography 
                    className={LoginFormClasses.header}
                    variant="h6" 
                    gutterBottom
                >
                  Reset Password
                </Typography>
                <TextInput
                    source="username"
                    label={translate('Email')}
                    defaultValue={decodedToken.sub}
                    validate={required()}
                    sx={{display: 'None'}}
                />
                <TextInput
                    autoFocus
                    source="password"
                    label={translate('New Password')}
                    type="password"
                    validate={required()}
                    fullWidth
                />
                <TextInput
                    source="confirmPassword"
                    label={translate('Confirm Password')}
                    type="password"
                    validate={required()}
                    fullWidth
                />

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginFormClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        'Reset Password'
                    )}
                </Button>
            </CardContent>
        </StyledForm>
    );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
    header: `${PREFIX}-header`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.header}`]: {
        textAlign: 'center',
    },
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

export interface LoginFormProps {
    redirectTo?: string;
    className?: string;
}

interface FormData {
    username: string;
    password: string;
}
ResetPassword.propTypes = {
    redirectTo: PropTypes.string,
};
